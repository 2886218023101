<template>
  <fragment>
    <overlay-free-downgrade />
  </fragment>
</template>

<script lang="ts">
import { Fragment } from 'vue-fragment'

import OverlayFreeDowngrade from '~/components/overlays/OverlayFreeDowngrade.vue'

export default {
  name: 'OverlayContainer',
  components: {
    Fragment,
    OverlayFreeDowngrade,
  },
}
</script>
